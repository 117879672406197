import React from "react";

const MatchHeader = (props) => {
    const matchInfo = props.matchInfo;
  return (
    <main className="md:px-[40px] px-[20px]  w-full lg:py-[150px] pt-[120px] pb-[100px]  bg-black flex items-center justify-start relative z-10 overflow-hidden">
      <img className="animation sm:h-full max-w-[600px] w-full h-full  absolute  top-0 left-[60%]  translate-x-[-60%] transition duration-300 z-[-1]" src="/image/academy/shape.png" />
      <section className="hero_content">
        <div>
          {/* <span className="mb-4 px-2 py-1 bg-red text-white font-secondary text-[14px] font-semibold uppercase inline-block">
          EXPLORE ACADEMY
          </span> */}
          <h1 className="font-bold mb-5 font-primary uppercase lg:text-[55px] sm:text-[40px] text-[35px] text-[#CFA855] leading-none">
           MATCH REPORT
          </h1>
        </div>
        <div className="absolute flex items-center gap-1 text-[30px] m-auto">
            <h1 className="text-white text-[20px]">{matchInfo.isHome?"AJ Sport" : matchInfo.opposingTeam}</h1>
            <img src={matchInfo.isHome?`/image/aj_logo.png`:'/image/badge_template.png'} className="h-[50px]"/>
            <h2 className="bg-white w-min py-1 px-4 text-[50px]">{matchInfo.home}</h2>
            <h2 className="bg-white w-min py-1 px-4 text-[50px]">{matchInfo.away}</h2>
            <img src={matchInfo.isHome?`/image/badge_template.png`:'/image/aj_logo.png'} className="h-[50px]"/>
            <h1 className="text-white text-[20px]">{matchInfo.isHome?matchInfo.opposingTeam: "AJ Sport" }</h1>

        </div>
        {/* <div className="absolute bottom-0  lg:left-[45%] left-[50%] lg:translate-x-0 translate-x-[-50%] "> */}
          {/* <img
            src="/image/academy/header-img.png"
            className="sm:max-w-[320px] max-w-[300px] h-auto object-cover"
            alt=""
          /> */}
        {/* </div> */}
      </section>
    </main>
  );
};

export default MatchHeader;
