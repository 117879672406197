import React from "react";
import NavbarTwo from "../NavFooter/NavbarTwo";
import MatchHeader from "../element/MatchSection/MatchHeader";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";

const Match = () => {
    const matchInfo = {
        isHome: true,
        opposingTeam: 'Rossineri',
        home: 4,
        away: 4,
        date: "25/03/2025",
        title: "A game of 2 Halves",
        description: "AJ Sport FC 4-4 The Rossoneri FC a game of 2 halves. HT 1-3 down after taking the lead we got hit with 3 long ball in behind. FT 4-4 coach Jay with hat trick ⚽⚽⚽ to assure a point to the team 🤝❤⚽ one more game to go on our push for promotion and Div 1 football 🔥⚽. #match #matchday⚽ #sport #photography #action #shot #media #season",
        imgUrls: ["/image/matchday/matchday_1.jpg", "/image/matchday/matchday_2.jpg", "/image/matchday/matchday_3.jpg", "/image/matchday/matchday_4.jpg", "/image/matchday/matchday_5.jpg"]
    }
    return(
        <div>
            <NavbarTwo variant={2}/>
            <MatchHeader matchInfo={matchInfo}/>
            <section className="sm:flex-row flex-col flex sm:py-10 pt-7 pb-5 px-6 sm:px-10">
                
                <div className="sm:w-1/2 sm:text-left text-justify">
                <h1 className="font-medium uppercase text-[40px]">{matchInfo.title}</h1>
                <div className="bg-red h-[2px] w-full sm:w-1/4 -translate-y-[5px]"/>
                
                <p className="mb-2">{matchInfo.description}</p>

                </div>
                <Swiper
                    slidesPerView={2}
                    spaceBetween={10}
                    navigation={true}
                    grabCursor={true}
                    breakpoints={{
                        320: {
                        slidesPerView: 1,
                        },
                        998: {
                        slidesPerView: 1.3,
                        },
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                    className="sm:h-[500px] h-[350px] sm:w-1/2 w-full"
                >
                    {matchInfo.imgUrls.map((imageSrc, index) => {
                        return(
                            <SwiperSlide key={index}>
                                <img src={imageSrc}/>
                            </SwiperSlide>

                        )
                    })}
                </Swiper>
            </section>
            
        </div>
    )
}

export default Match;